<template>
  <div class="container">
        <!-- 整个注册盒子 -->
        <el-form ref="loginForm" 
              :model="loginForm" 
              :rules="loginRules" >

          <div class="loginbox">
              <div class="logo">
                <img v-if="!isTest" src="../../assets/img/logo3.png" style="width:600px;height:auto;">
                <div v-if=" isTest" style="margin-top:10px; font-size: 28px; color:white">系 统 登 录</div>
              </div>

              <div class="userbox"> 
                <div class="iconfont">&#xe8c8;</div> 
                 <el-form-item prop="username">
                  <el-input  ref="username" v-model="loginForm.username" placeholder="用户名/邮箱" type="text" id="user"   />
                 </el-form-item>
              </div>
              <br>

              <div class="pwdbox">
                <span class="iconfont">&#xeb6a;</span>
                <el-form-item prop="password">
                  <el-input  ref="password" v-model="loginForm.password" placeholder="密码" type="password" id="password" show-password/>
                </el-form-item>
                <el-input  v-show="false" ref="md5password" v-model="loginForm.md5password" type="password" id="md5pwd"/><br/>
              </div>
              <br>

              <div class="log-box">
                <el-button type="primary"  class="loss_passwd-btn" @click="redirect_changepassword">忘记密码？</el-button>
                <el-button type="primary"  class="login_btn"  @click="handleLogin">Login</el-button>
              </div>
              
              <!-- <br>
              <el-button type="primary"  class="register_btn" @click="redirect_register">若无账号请点击注册</el-button> -->

          </div>
      </el-form>
  </div>
</template>

<script>
import {login} from "@/utils/api/index.js"
import { sha256 } from 'js-sha256'
import md5 from "md5";

export default {
  name: "Login",
  
  data:function (){
    // rule参数是占位用的，可以不用，必须要有，否则无法匹配callback函数
     var validatePassword = (rule,value,callback) => {
      if (value.length < 6) {
        callback(new Error('密码不能少于6位'))
      }else{
        callback();
      }
    }
    var validateUsername = (rule,value,callback) => {
      var reg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,3}){1,2})$/
      if(reg.test(value)){
        this.isEmail = 1
        callback()
      }else{
        this.isEmail = 0
        if (value.length >7 ) {
          callback(new Error('用户名不能超过7个字符'))
        } else {
          callback();
        }
      }
    }
    return {
      isTest: false,
      isEmail:0,
      loginForm: {
        username: '',
        password: '',
      },
      loginRules: {
        username: [{ required: true, trigger: 'blur', validator: validateUsername}],
        password: [{ required: true, trigger: 'blur', validator: validatePassword}]
      },
    }
  },

  created(){
    this.isTest = window.isTest;
  },
 
  methods:{
    redirect_changepassword() {
      this.$router.replace({ path: "/changepassword" });
    },

    redirect_register(){
      this.$router.replace({ path: "/register" });
     },

    handleLogin() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          // //SHA256加密密码
          // this.loginForm.md5password = sha256(this.loginForm.password)
          this.loginForm.md5password = md5(this.loginForm.password);
          // 当isEmail为 1 ，说明username是一个邮箱（已经经过验证），当isEmail为0，说明是普通用户名
          let params = {
              "isEmail":this.isEmail,
              "username":this.loginForm.username,
              "password":this.loginForm.md5password
          }
          login(params)
            .then(res=>{
              //console.log(res)
              let resultData = res.data.resultData
              if(res.data.resultCode == 0){
                //登陆成功
                localStorage.setItem('accessToken', resultData.accessToken);
                localStorage.setItem('refreshToken', resultData.refreshToken);
                localStorage.setItem("expireTime",resultData.expireTime)
                localStorage.setItem('userId', resultData.user.userId);
                localStorage.setItem('username', resultData.user.username);
                this.$router.push("/home");
              }else{
                //登陆失败，需要反馈
                this.$notify.error({
                  title:"ERROR",
                  message: "用户名或密码错误，请重新输入",
                })
              }
            })
            .catch(err=>{
              this.$notify.error({
                title:"ERROR",
                message: "服务器或response处理错误",
              })
            })
        } else {
          console.log('用户名或密码格式错误')
          return false
        }
      })
    }

  }
}
</script>

<style scoped>

.container{
  height: 100vh;
  width: 100%;
  background: url("../../assets/img/warship.jpg") no-repeat;
  background-position: center;
  background-size: cover;
  position: fixed;
  overflow: hidden;
}

.loginbox{
    display:block;
    position:absolute;
    width:800px;
    height:410px;
    top:40%;
    left:50%;
    transform:translate(-50%,-50%); 
    border-radius: 25px;
    background-color:rgba(0, 0, 0, 0.35);
    box-shadow: 0 12px 16px 0  rgba(87, 92, 146, 0.24), 0 17px 50px 0 #404668; 
}

.logo{
    display: table-cell;
    /* 垂直居中 */
    vertical-align: middle;
    /* 水平居中 */
    text-align: center;
    margin-top:20px ;
    width: 800px;
    height: 100px;

}

.userbox{
    opacity:0.9;
    margin-top:40px;
    height:40px;
    width:300px;
    display: flex; 
    margin-left:250px;
    background-color: white;
    border-radius: 10px;
}


.pwdbox{ 
    opacity:0.9;
    height:40px;
    width:300px;
    display: flex; 
    margin-left:250px;
    margin-top:10px;
    background-color: white;
    border-radius: 10px;
}


.background{
    width:570px;
    background-image:url('../../assets/img/warship.jpg');
    background-size:cover ;
    font-family:sans-serif;
}

*>>>.el-form-item--small .el-form-item__error {
    padding-top: 5px;
}

*>>>.el-form-item__error {
  font-size: 12px;
}

.title{
    margin-top:320px;
    font-weight:bold;
    font-size:20px;
    color:#4E655D;
    
}
.title:hover{
     font-size:21px;
     transition: all 0.4s ease-in-out;
     cursor: pointer;
}
.uesr-text{
     position:left;
}

/* 去除el-input的边框 */
*>>>.el-input__inner{
    border: none;
    font-size: 15px;
    margin-top: 2px;
}

.inputDeep .el-input--medium .el-input__inner{
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-radius: 0;
}
.el-input{
    margin-left: 0px;
    margin-top: 3px;
    margin-right: 10px;
    background-color:transparent;
    height:37px;
    font-family:sans-serif;
    font-size:14px;
    color:#062280;
    font-weight:bold;
}

.el-input:focus{
    background-color:transparent;
    transition: all 0.2s ease-in;
    font-family:sans-serif;
    font-size:16px;
    color:#062280;
    font-weight:bold;
}

.el-input:hover{
    background-color:transparent;
    transition: all 0.2s ease-in;
    font-family:sans-serif;
    font-size:16px;
    color:#062280;
    font-weight:bold;
}
 
.log-box{
    font-size:15px;
    display: flex;
    justify-content: space-between ;
    width:225px;
    height: 40px;
    margin-left:300px;
    color:#4E655D;
    margin-top: 10px;
    align-items: center;
   
}
.log-box-text{
    color:#d6d5d5;
    font-size:15px;
    text-decoration:none;
    }

.login_btn{
    background-color: #4f796a; /* Green */
    border: none;
    color: #e2e0e0;
    padding: 8px 30px;
    text-align: center;
    text-decoration: none;
    font-size: 18px;
    border-radius: 20px;
    font-weight:bold;
    outline:none;
}
.login_btn:hover{
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
    cursor: pointer;
     background-color: #0b5137;
      transition: all 0.2s ease-in;
}


.loss_passwd-btn{
  background-color: transparent; 
  text-decoration:underline;
  border: none;
  text-decoration: none;
  color: #e2e0e0;
  font-size: 15px;
  display: flex;
}
.loss_passwd-btn:hover{
    font-weight:bold;
    cursor: pointer;
}

.register_btn{
    background-color: transparent; /* Green */
    border: none;
    text-decoration: none;
    font-size: 15px;
    /* border-radius: 20px;   */
     color:#d6d5d5;
    text-decoration:underline;
    display: flex;
    margin-top:15px;
    margin-left:320px;
    outline:none;
    
}
.register_btn:hover{
    font-weight:bold;
    cursor: pointer;
}
@font-face {
  font-family: "iconfont";
  src: url('../../assets/css/iconfont/iconfont.woff2?t=1668758935728') format('woff2'),
       url('../../assets/css/iconfont/iconfont.woff?t=1668758935728') format('woff'),
       url('../../assets/css/iconfont/iconfont.ttf?t=1668758935728') format('truetype');
}

.iconfont {
    font-family: "iconfont" !important;
    font-size: 27px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height:22px;
    color:#051c65;
    margin-left:15px;
    margin-right:15px;
    margin-top:7px;
}

.icon-yuechi_o:before {
  content: "\eb6a";
}

.icon-yonghu-xianxing:before {
  content: "\e8c8";
}


</style>